import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    Headway.init(this.config());
  }
  
  config() {
    return {
      account: this.data.get('account'),
      selector: `[data-headway-selector=${this.data.get('selector')}]`
    }
  }
}
