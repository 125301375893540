// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import HillChart from '../lib/hillchart';
import { select } from 'd3-selection';

export default class extends Controller {
  static targets = [ "chart", 'pointName', 'colorPicker', "pointsField", "dotList" ]

  connect() {
    const draggable = this.data.has('draggable')
    this.hillChart = new HillChart(this.chartTarget, draggable);

    // draw from data
    if (this.data.has('points')) {
      let points = JSON.parse(this.data.get('points'));
      this.hillChart.items = points;
      this.hillChart.renderDots(points, true);
      this.populateDotList()
    } else if (this.pointsFieldTarget) {
      let points = JSON.parse(this.pointsFieldTarget.value);
      if (points.length > 0) {
        this.hillChart.items = points;
        this.hillChart.renderDots(points, true);
        this.populateDotList()
      }
    }

    // Save
    // document.getElementById("save").onclick = function () {
    //   let snapShotItems = [];
    //   Array.from(document.getElementsByClassName('group')).forEach(group => {
    //     const newItem = { color: group.getElementsByTagName('circle')[0].getAttribute('fill'), label: group.getElementsByTagName('text')[0].innerHTML, x: group.getAttribute('xValue'), y: group.getAttribute('yValue') };
    //     snapShotItems.push(newItem);
    //   })

    //   let save = JSON.stringify(snapShotItems);
    // };
  }

  submit(e) {
    this.setPointsField();
  }

  populateDotList() {
    if (this.hasDotListTarget) {
      let self = this;
      self.dotListTarget.innerHTML = ''; // reset
      this.hillChart.items.forEach(function(item, index) {
        let li = document.createElement("li");
        li.setAttribute('data-action', 'click->hillchart#removeDot');
        li.setAttribute('data-dot-index', index);
        li.innerHTML = '<a href="#"><i class="far fa-times-circle"></i><span data-dot-index="' + index + '" class="pl-1">' + item['label'] + '</span></a>';
        self.dotListTarget.appendChild(li)
      })
    }
  }

  addDot(e) {
    let controller = this;
    e.preventDefault();
    let inputfield = controller.pointNameTarget;
    let colorpicker = controller.colorPickerTarget;
    let itemName = inputfield.value;
    inputfield.value = '';
    let colorName = colorpicker.options[colorpicker.selectedIndex].value;
    let todoMarker = "- [ ]"
    if (itemName.includes(todoMarker)) {
      // bulk add from todo markdown
      let itemNames = itemName.split(todoMarker).map(str => str.trim());
      itemNames.forEach((itemName, index) => {
        if (itemName.length > 0) {
          controller.hillChart.renderDot(
            { color: colorName, label: itemName, x: index, y: controller.hillChart.fn(0) }
          );
        }
      })
    } else {
      // assume single dot
      controller.hillChart.renderDot({ color: colorName, label: itemName, x: 0, y: controller.hillChart.fn(0) });
    }
    controller.populateDotList()
  }

  removeDot(e) {
    e.preventDefault();
    
    // get points before reset
    let points = this.hillChart.items;

    // reset chart
    this.resetChart()

    // remove item at selected index
    let i = parseInt(e.target.dataset.dotIndex);
    let removedDot = points.splice(i, 1);
    
    if (points.length > 0) {
      this.hillChart.renderDots(points, true);
    }
    this.hillChart.items = points;
    this.populateDotList();
  }

  setPointsField() {
    this.pointsFieldTarget.value = JSON.stringify(this.hillChart.items);
  }

  resetChart() {
    this.hillChart.items = [];
    this.hillChart.resetChart();
  }

  download(e) {
    e.preventDefault()
    let svgString = this.getSVGString(select('svg').node());
    this.svgString2Image(svgString, 1000, 300, 'png', this.save); // passes Blob and filesize String to the callback
  }

  // credit to http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177
  // Below are the functions that handle actual exporting:
  // getSVGString ( svgNode ) and svgString2Image( svgString, width, height, format, callback )
  getSVGString(svgNode) {
    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
    let cssStyleText = getCSSStyles(svgNode);
    appendCSS(cssStyleText, svgNode);
    let serializer = new XMLSerializer();
    let svgString = serializer.serializeToString(svgNode);
    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
    svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
    return svgString;

    function getCSSStyles(parentElement) {
      var selectorTextArr = [];
      // Add Parent element Id and Classes to the list
      selectorTextArr.push('#' + parentElement.id);
      for (var c = 0; c < parentElement.classList.length; c++)
        if (!contains('.' + parentElement.classList[c], selectorTextArr))
          selectorTextArr.push('.' + parentElement.classList[c]);
      // Add Children element Ids and Classes to the list
      var nodes = parentElement.getElementsByTagName("*");
      for (var i = 0; i < nodes.length; i++) {
        var id = nodes[i].id;
        if (!contains('#' + id, selectorTextArr))
          selectorTextArr.push('#' + id);
        var classes = nodes[i].classList;
        for (var c = 0; c < classes.length; c++)
          if (!contains('.' + classes[c], selectorTextArr))
            selectorTextArr.push('.' + classes[c]);
      }
      // Extract CSS Rules
      var extractedCSSText = "";
      for (var i = 0; i < document.styleSheets.length; i++) {
        var s = document.styleSheets[i];
        try {
          if (!s.cssRules) continue;
        } catch (e) {
          if (e.name !== 'SecurityError') throw e; // for Firefox
          continue;
        }
        var cssRules = s.cssRules;
        for (var r = 0; r < cssRules.length; r++) {
          if (contains(cssRules[r].selectorText, selectorTextArr))
            extractedCSSText += cssRules[r].cssText;
        }
      }
      return extractedCSSText;
      function contains(str, arr) {
        return arr.indexOf(str) === -1 ? false : true;
      }
    };

    function appendCSS(cssText, element) {
      var styleElement = document.createElement("style");
      styleElement.setAttribute("type", "text/css");
      styleElement.innerHTML = cssText;
      var refNode = element.hasChildNodes() ? element.children[0] : null;
      element.insertBefore(styleElement, refNode);
    };
  };

  svgString2Image(svgString, width, height, format, callback) {
    var format = format ? format : 'png';
    var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    var image = new Image();
    image.onload = function () {
      context.clearRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);
      canvas.toBlob(function (blob) {
        var filesize = Math.round(blob.length / 1024) + ' KB';
        if (callback) callback(blob, filesize);
      });
    };
    image.src = imgsrc;
  };

  save(dataBlob, filesize) {
    let formatDate = function(d) {
      return d.toLocaleString("en-US", { month: "long" }) + "-" + d.getDate() + "-" + d.getFullYear() + "_" +
        ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }
    let filename = 'hillchart-' + formatDate(new Date()) + '.png';
    saveAs(dataBlob, filename); // FileSaver.js function
  }
}
